<template>
  <div class="login">
    <div class="login-logo">
      <img :src="imgLogo" class="logo-icon" />
      <p class="logo-word">零数数字资产服务平台管理后台</p>
    </div>
    <div class="loginBox">
      <img :src="imgBg" class="loginImg" />
      <div class="loginCont">
        <div class="login-box">
          <div class="login-pos">
            <div class="login-form">
              <div class="login-title">
                <p>登录</p> 
                <div class="line"></div>
              </div>
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="0px"
                class="login-ruleForm"
              >
                <el-form-item prop="username">
                  <p class="input-label">用户名/账号</p>
                  <el-input
                    v-model="ruleForm.username"
                    placeholder="请输入用户名/账号"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <p class="input-label">密码</p>
                  <el-input
                    type="password"
                    placeholder="请输入密码"
                    show-password
                    v-model="ruleForm.password"
                    @keyup.enter.native="submitForm('ruleForm')"
                  ></el-input>
                </el-form-item>

                <div class="login-btn">
                  <el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
                </div>
                <template v-if="msg">
                  <el-alert style="postion:relative;left:-23px;background:#ffffff;" :title="msg" type="error" :closable="false"></el-alert>
                </template>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="footer">—— 区块链·点亮数字未来 ——</p>
  </div>
</template>
    
<style  scoped>
</style>
<script>
import { mapActions } from "vuex";
import "@/scss/login.scss";
import { AES, enc, mode, pad } from "crypto-js";
import API from "@/api/api_login";
import { COMMON_MESSAGE } from "@/util/common.js"; 
import { userInfo } from "../vuex/getters";

export default {
  data() {
    return {
      imgLogo: require('@/assets/login/logo.png'),
      imgBg: require('@/assets/login/login.png'),
      ruleForm: {
        username: "",
        password: ""
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" }
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      },
      msg: null
    };
  },
  methods: {
    ...mapActions(["setUserInfo"]),
    submitForm(formName) {
      const self = this;
      self.$refs[formName].validate(valid => {
        if (valid) {
          // let key = enc.Utf8.parse("abcdefghigklmn12");
          // let iv = enc.Utf8.parse("abcdefghigklmn12");
          // let ciphertext = AES.encrypt(self.ruleForm.password, key, {
          //   iv: iv,
          //   mode: mode.CBC,
          //   padding: pad.ZeroPadding
          // });
          // console.log(ciphertext.toString())
          let params = {
            username: self.ruleForm.username,
            // password: ciphertext.toString()
            password: self.ruleForm.password
          };
          API.loginIn(params).then(({ data }) => {
            const code = data.code;
            if(code == 200) {
              const baseUser = data.data || {};
              sessionStorage.setItem("tokenDate", new Date());
              sessionStorage.setItem("userInfo", JSON.stringify(baseUser));
              this.setUserInfo(baseUser);
              this.$router.push({name:'index'});
            }else {
              this.$message.error({
                message: data.msg || this.$CommonMsg['networkErr']
              })
            }
          });

          // API.login(data).then(response => {
          //   // console.log(response);
          //   if (
          //     response.data.code === 0 &&
          //     response.data.data.hasOwnProperty("access_token")
          //   ) {
          //     localStorage.setItem(
          //       "perms",
          //       AES.encrypt(
          //         response.data.data.access_token,
          //         "hteAdminWeb"
          //       ).toString()
          //     );
          //     API.getUserInfo({}).then(res => {
          //       if (res.status == 200 && res.data.code == 0) {
          //         let data = res.data.data.data;
          //         let companyId = 0;
          //         let roleId = data.id;
          //         let roleName = data.name;
          //         let userId = data.id;
          //         let userName = data.username;
          //         let token = response.data.data.access_token;

          //         let userInfo = `${companyId}_${roleId}_${roleName}_${userId}_${userName}_${token}`;
          //         sessionStorage.setItem("userInfo", userInfo);
          //         sessionStorage.setItem("tokenDate", new Date());
          //         this.$router.push({name:'index'})

          //       }
          //     });
          //   } else {
          //     //   console.log("登录异常");
          //     this.msg = response.data.data.error_description || "登录失败";
          //   }
          // });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  },
  created() {},
  mounted() {
    // let $body = document.getElementsByTagName("body")[0];
    // if (this.$route.name == "login") {
    //   $body.classList.add("login");
    // } else {
    //   $body.classList.remove("login");
    // }
  }
};
</script>
<style lang="scss" scoped>
.logo-icon{
  width: 20px;
}
</style>